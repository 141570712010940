import { connect } from 'dva';
import React, { useRef, useEffect } from 'react';
import { Popup } from 'semantic-ui-react';
import './index.scss';
import './slider.scss';
import * as KeyCode from 'keycode-js';

function VolumeControl({ muted = false, volume = true, dispatch }) {
  const handleVolumeChange = ({ target: { value } }) => {
    if (muted) {
      dispatch({type: 'watch/media_mute', payload: false})
    }
    dispatch({type: 'watch/media_volume', payload: value})

    if (value < 0.04) {
      dispatch({type: 'watch/media_mute', payload: true})
    }
  };

  const handleButtonClick = () => {
    dispatch({type: 'watch/media_mute'})
  };

  const handleVolumeKeyDown = (e) => {
    const { keyCode } = e;
    if (keyCode === KeyCode.KEY_LEFT || keyCode === KeyCode.KEY_RIGHT) {
      e.preventDefault();
    }
  };

  const sliderRef = useRef();
  useEffect(() => {
    window.focusVolumeSlider = () => {
      sliderRef.current?.focus();
    };
    
    return () => {
      delete window.focusVolumeSlider;
    };
  }, []);

  const iconName =
    muted || volume < 0.04 ? 'volume_off' : volume >= 0.6 ? 'volume_up' : 'volume_down';

  return (
    <div className="watch-volume-ctrl">
      <Popup
        inverted
        wide
        basic
        position="top center"
        offset="0, 15px"
        openOnTriggerClick={false}
        openOnTriggerFocus
        closeOnTriggerBlur
        content={<strong>{muted ? 'Unmute (m)' : 'Mute (m)'}</strong>}
        trigger={
          <button
            className="watch-ctrl-button"
            onClick={handleButtonClick}
            aria-label={muted ? 'Unmute' : 'Mute'}
            id="volume-mute-btn"
            // position="bottom"
          >
            <span className="watch-btn-content" tabIndex="-1">
              <i className="material-icons">{iconName}</i>
            </span>
          </button>
        }
      />

      <Popup
        inverted
        wide
        basic
        position="top center"
        offset="0, 15px"
        openOnTriggerClick={false}
        openOnTriggerFocus
        closeOnTriggerBlur
        content={<strong>Volume: {Math.floor(volume * 100)}%</strong>}
        trigger={
          <input
            ref={sliderRef}
            id="volume-slider"
            className="volume-slider"
            aria-label={`Volume at ${Math.floor(volume * 100)} %`}
            type="range"
            min={0}
            max={1}
            step={0.05}
            value={muted ? 0 : volume}
            onKeyDown={handleVolumeKeyDown}
            onChange={handleVolumeChange}
          />
        }
      />
    </div>
  );
}

export default connect(({ playerpref }) => ({
  muted: playerpref.muted, volume: playerpref.volume
}))(VolumeControl);
